var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme.showLanguage == false
    ? _c(
        "el-dropdown",
        {
          on: { command: _vm.handleCommand },
          scopedSlots: _vm._u(
            [
              {
                key: "dropdown",
                fn: function() {
                  return [
                    _c(
                      "el-dropdown-menu",
                      [
                        _c("el-dropdown-item", { attrs: { command: "zh" } }, [
                          _vm._v("中文简体")
                        ]),
                        _c("el-dropdown-item", { attrs: { command: "en" } }, [
                          _vm._v("English")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3382601339
          )
        },
        [_c("vab-icon", { attrs: { icon: "translate" } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }