var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        "append-to-body": "",
        "custom-class": "vab-drawer",
        direction: "rtl",
        size: "280px",
        title: _vm.translateTitle("主题配置"),
        visible: _vm.drawerVisible
      },
      on: {
        "update:visible": function($event) {
          _vm.drawerVisible = $event
        }
      }
    },
    [
      _c("el-scrollbar", { staticClass: "theme-scrollbar" }, [
        _c(
          "div",
          { staticClass: "el-drawer__body" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: { "label-position": "left", model: _vm.theme }
              },
              [
                _c(
                  "el-divider",
                  { attrs: { "content-position": "left" } },
                  [
                    _c("vab-icon", { attrs: { icon: "settings-3-line" } }),
                    _vm._v(" " + _vm._s(_vm.translateTitle("常用设置")) + " ")
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "label",
                              { staticClass: "el-form-item__label" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.translateTitle("布局")) + " "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: _vm.translateTitle(
                                        "布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局"
                                      ),
                                      effect: "dark",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("vab-icon", {
                                      attrs: { icon: "question-line" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { disabled: _vm.device === "mobile" },
                        model: {
                          value: _vm.theme.layout,
                          callback: function($$v) {
                            _vm.$set(_vm.theme, "layout", $$v)
                          },
                          expression: "theme.layout"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "column",
                          attrs: {
                            label: _vm.translateTitle("分栏"),
                            value: "column"
                          }
                        }),
                        _c("el-option", {
                          key: "comprehensive",
                          attrs: {
                            label: _vm.translateTitle("综合"),
                            value: "comprehensive"
                          }
                        }),
                        _c("el-option", {
                          key: "vertical",
                          attrs: {
                            label: _vm.translateTitle("纵向"),
                            value: "vertical"
                          }
                        }),
                        _c("el-option", {
                          key: "horizontal",
                          attrs: {
                            label: _vm.translateTitle("横向"),
                            value: "horizontal"
                          }
                        }),
                        _c("el-option", {
                          key: "common",
                          attrs: {
                            label: _vm.translateTitle("常规"),
                            value: "common"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("主题") } },
                  [
                    _c(
                      "el-select",
                      {
                        on: { change: _vm.setTheme },
                        model: {
                          value: _vm.theme.themeName,
                          callback: function($$v) {
                            _vm.$set(_vm.theme, "themeName", $$v)
                          },
                          expression: "theme.themeName"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "default",
                          attrs: {
                            label: _vm.translateTitle("默认"),
                            value: "default"
                          }
                        }),
                        _c("el-option", {
                          key: "ocean",
                          attrs: {
                            label: _vm.translateTitle("海洋之心"),
                            value: "ocean"
                          }
                        }),
                        _c("el-option", {
                          key: "green",
                          attrs: {
                            label: _vm.translateTitle("绿荫草场"),
                            value: "green"
                          }
                        }),
                        _c("el-option", {
                          key: "white",
                          attrs: {
                            label: _vm.translateTitle("碰触纯白"),
                            value: "white"
                          }
                        }),
                        _c("el-option", {
                          key: "red",
                          attrs: {
                            label:
                              _vm.translateTitle("月上重火") + "（非内置）",
                            value: "red"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("标签") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.theme.showTabs,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showTabs", $$v)
                        },
                        expression: "theme.showTabs"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "label",
                              { staticClass: "el-form-item__label" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle("标签图标")) +
                                    " "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: _vm.translateTitle(
                                        "标签开启时生效"
                                      ),
                                      effect: "dark",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("vab-icon", {
                                      attrs: { icon: "question-line" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("el-switch", {
                      attrs: { disabled: !_vm.theme.showTabs },
                      model: {
                        value: _vm.theme.showTabsBarIcon,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showTabsBarIcon", $$v)
                        },
                        expression: "theme.showTabsBarIcon"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "label",
                              { staticClass: "el-form-item__label" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle("标签风格")) +
                                    " "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: _vm.translateTitle(
                                        "标签开启时生效"
                                      ),
                                      effect: "dark",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("vab-icon", {
                                      attrs: { icon: "question-line" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { disabled: !_vm.theme.showTabs },
                        model: {
                          value: _vm.theme.tabsBarStyle,
                          callback: function($$v) {
                            _vm.$set(_vm.theme, "tabsBarStyle", $$v)
                          },
                          expression: "theme.tabsBarStyle"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "card",
                          attrs: {
                            label: _vm.translateTitle("卡片"),
                            value: "card"
                          }
                        }),
                        _c("el-option", {
                          key: "smart",
                          attrs: {
                            label: _vm.translateTitle("灵动"),
                            value: "smart"
                          }
                        }),
                        _c("el-option", {
                          key: "smooth",
                          attrs: {
                            label: _vm.translateTitle("圆滑"),
                            value: "smooth"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "label",
                              { staticClass: "el-form-item__label" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle("分栏风格")) +
                                    " "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: _vm.translateTitle(
                                        "分栏布局时生效"
                                      ),
                                      effect: "dark",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("vab-icon", {
                                      attrs: { icon: "question-line" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { disabled: _vm.theme.layout !== "column" },
                        model: {
                          value: _vm.theme.columnStyle,
                          callback: function($$v) {
                            _vm.$set(_vm.theme, "columnStyle", $$v)
                          },
                          expression: "theme.columnStyle"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "vertical",
                          attrs: {
                            label: _vm.translateTitle("纵向"),
                            value: "vertical"
                          }
                        }),
                        _c("el-option", {
                          key: "horizontal",
                          attrs: {
                            label: _vm.translateTitle("横向"),
                            value: "horizontal"
                          }
                        }),
                        _c("el-option", {
                          key: "card",
                          attrs: {
                            label: _vm.translateTitle("卡片"),
                            value: "card"
                          }
                        }),
                        _c("el-option", {
                          key: "arrow",
                          attrs: {
                            label: _vm.translateTitle("箭头"),
                            value: "arrow"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-divider",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { "content-position": "left" }
                  },
                  [
                    _c("vab-icon", { attrs: { icon: "settings-3-line" } }),
                    _vm._v(" " + _vm._s(_vm.translateTitle("其它设置")) + " ")
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("头部固定") } },
                  [
                    _c("el-switch", {
                      attrs: { disabled: _vm.theme.layout === "common" },
                      model: {
                        value: _vm.theme.fixedHeader,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "fixedHeader", $$v)
                        },
                        expression: "theme.fixedHeader"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("国际化") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.theme.showLanguage,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showLanguage", $$v)
                        },
                        expression: "theme.showLanguage"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("进度条") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.theme.showProgressBar,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showProgressBar", $$v)
                        },
                        expression: "theme.showProgressBar"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("刷新") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.theme.showRefresh,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showRefresh", $$v)
                        },
                        expression: "theme.showRefresh"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("搜索") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.theme.showSearch,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showSearch", $$v)
                        },
                        expression: "theme.showSearch"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("通知") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.theme.showNotice,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showNotice", $$v)
                        },
                        expression: "theme.showNotice"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.translateTitle("全屏") } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.theme.showFullScreen,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "showFullScreen", $$v)
                        },
                        expression: "theme.showFullScreen"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "el-drawer__footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSaveTheme } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("保存")) + " ")]
          ),
          _c("el-button", { on: { click: _vm.setDefaultTheme } }, [
            _vm._v(" " + _vm._s(_vm.translateTitle("恢复默认")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }