var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-drag-container" },
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "intf-dialog",
          attrs: {
            "close-on-click-modal": false,
            title: "粘贴板",
            visible: _vm.dialogVisible,
            width: "20%",
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 10, placeholder: "请输入内容" },
            model: {
              value: _vm.textarea,
              callback: function($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }