<template>
  <div>
    <!-- <el-select
      v-model="value"
      @change="selectClick"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VabProgress from 'nprogress'
import { getCompany } from '@/api/metrics'
export default {
  name: 'VabCompany',
  computed: {
    ...mapGetters({
      theme: 'settings/theme',
      extra: 'settings/extra',
      visitedRoutes: 'tabs/visitedRoutes',
    }),
  },
  data() {
    return {
      options: [],
      value: this.company.companyId,
    }
  },
  mounted() {
    // this.selectList()
  },
  methods: {
    selectClick(val) {
      this.company.setCompanyId(val)
      this.refreshRoute()
    },
    async selectList() {
      const res = await getCompany()
      this.options = res.data
    },
    async refreshRoute() {
      if (this.theme.showProgressBar) VabProgress.start()
      this.$baseEventBus.$emit('reload-router-view')
      setTimeout(() => {
        if (this.theme.showProgressBar) VabProgress.done()
      }, 200)
    },
  },
}
</script>
<style lang="scss" scoped>
</style>