var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorLogs.length > 0
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                title:
                  "admin-pro(vue-admin-beautiful pro 版本) 异常捕获(温馨提示：错误必须解决)",
                visible: _vm.dialogTableVisible,
                width: "70%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogTableVisible = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialogTableVisible = false
                              }
                            }
                          },
                          [_vm._v("取 消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.clearAll }
                          },
                          [_vm._v("暂不显示")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                523777005
              )
            },
            [
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.errorLogs } },
                [
                  _c("el-table-column", {
                    attrs: { label: "报错路由" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "a",
                                { attrs: { href: row.url, target: "_blank" } },
                                [
                                  _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s(row.url))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2176999649
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "错误信息" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v(_vm._s(row.err.message))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2907049969
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "错误详情", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    trigger: "hover"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function() {
                                          return [
                                            _c("el-button", [_vm._v("查看")])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_vm._v(" " + _vm._s(row.err.stack) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2595311188
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }