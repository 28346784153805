import request from '@/utils/request'
let resquest = "/api/"

//获取公司下拉框
export function getCompany(params) {
  return request({
    url: `${resquest}api/base/getCompany`,
    method: 'get',
    params
  })
}

//获取公司岗位类型下拉框
export function getDempType(params) {
  return request({
    url: `${resquest}api/base/getDempType`,
    method: 'get',
    params
  })
}

//获取公司部门类型下拉框
export function getDemp(data) {
  return request({
    url: `${resquest}api/base/getDemp`,
    method: 'post',
    data
  })
}

//营收-成本分析
export function getRevCost(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/getRevCost`,
    method: 'post',
    data
  })
}

//订单量-货量分析
export function getOrdersTeus(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/getOrdersTeus`,
    method: 'post',
    data
  })
}

//应收-应付款分析
export function getRecPayAble(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/getRecPayAble`,
    method: 'post',
    data
  })
}

//部门-订单量分析
export function getDempOrders(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/getDempOrders`,
    method: 'post',
    data
  })
}

//部门-货量分析
export function getDempTeus(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/getDempTeus`,
    method: 'post',
    data
  })
}