<template>
  <div class="dialog-drag-container">
    <el-dialog
      :close-on-click-modal="false"
      :title="'粘贴板'"
      :visible.sync="dialogVisible"
      class="intf-dialog"
      width="20%"
      :modal="false"
      v-dialogDrag
    >
      <el-input
        type="textarea"
        :rows="10"
        placeholder="请输入内容"
        v-model="textarea"
      ></el-input>
      <!-- <img :src="url" alt="" style="width:200px;" /> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogDrag',
  data() {
    return {
      textarea: '',
      dialogVisible: false,
    }
  },
  methods: {
    showEdit(row) {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.intf-dialog {
  overflow: hidden;
}
::v-deep {
  .el-dialog__wrapper {
    pointer-events: none;
  }
  .el-dialog__body {
    padding: 0px !important;
  }
  .el-dialog {
    pointer-events: auto;
    outline:none;
border:#1890ff 1px solid!important;
  }
  .el-dialog__title{
    font-size: 14px;
  }
}
</style>
