import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import store from './store'
import router from './router'
import './registerServiceWorker'
import '@/vab'
import axios from 'axios'
import echarts from 'echarts';
import company from '@/utils/company'
import '@/utils/directives'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)
/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
// import { baseURL } from './config'
// import { isExternal } from '@/utils/validate'

// if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
//   const { mockXHR } = require('@/utils/static')
//   mockXHR()
// }
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts;
Vue.prototype.company = company
Number.prototype.toFixed = function (n) {

  if (n > 20 || n < 0) {
    throw new RangeError('toFixed() digits argument must be between 0 and 20');
  }
  const number = this;
  if (isNaN(number) || number >= Math.pow(10, 21)) {
    return number.toString();
  }

  if (typeof (n) == 'undefined' || n == 0) {
    return (Math.round(number)).toString();
  }
  let result = number.toString();
  const arr = result.split('.');
  // 整数的情况
  if (arr.length < 2) {
    result += '.';
    for (let i = 0; i < n; i += 1) {
      result += '0';
    }
    return result;
  }
  const integer = arr[0];
  const decimal = arr[1];
  if (decimal.length == n) {
    return result;
  }

  if (decimal.length < n) {
    for (let i = 0; i < n - decimal.length; i += 1) {
      result += '0';
    }
    return result;
  }
  result = integer + '.' + decimal.substr(0, n);
  const last = decimal.substr(n, 1);
  // 四舍五入，转换为整数再处理，避免浮点数精度的损失
  if (parseInt(last, 10) >= 5) {
    const x = Math.pow(10, n);
    result = (Math.round((parseFloat(result) * x)) + 1) / x;
    result = result.toFixed(n);
  }
  return result;
}
new Vue({
  el: '#app',
  i18n,
  store,
  axios,
  router,
  render: (h) => h(App),
})
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
  localStorage.clear()
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload()
}
