import request from '@/utils/request'
let resquest = "/api/"

export function getRouterList(params) {
  return request({
    url: `${resquest}baseSystem/getModuleList`,
    method: 'get',
    params,
  })
}
