var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme.showSearch == false
    ? _c(
        "span",
        [
          _c("vab-icon", {
            attrs: { icon: "search-line" },
            on: { click: _vm.openDialog }
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                visible: _vm.dialogVisible,
                width: "40%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  attrs: { model: _vm.queryForm },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c("el-autocomplete", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: {
                          "fetch-suggestions": _vm.querySearchAsync,
                          "select-when-unmatched": ""
                        },
                        on: { select: _vm.handleSelect },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prefix",
                              fn: function() {
                                return [
                                  _c("vab-icon", {
                                    attrs: { icon: "search-line" }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2799347713
                        ),
                        model: {
                          value: _vm.queryForm.searchWord,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "searchWord", $$v)
                          },
                          expression: "queryForm.searchWord"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }