var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item.meta && !_vm.item.meta.hidden
    ? _c(
        _vm.menuComponent,
        { tag: "component", attrs: { "item-or-menu": _vm.item } },
        [
          _vm.item.children && _vm.item.children.length
            ? [
                (_vm.layout === "horizontal" &&
                  _vm.item.children.length > 18) ||
                (_vm.layout !== "horizontal" &&
                  _vm.collapse &&
                  _vm.item.children.length > 18)
                  ? _c(
                      "el-scrollbar",
                      { staticStyle: { height: "86vh" } },
                      _vm._l(_vm.item.children, function(route) {
                        return _c("vab-menu", {
                          key: route.path,
                          attrs: { item: route }
                        })
                      }),
                      1
                    )
                  : _vm._l(_vm.item.children, function(route) {
                      return _c("vab-menu", {
                        key: route.path,
                        attrs: { item: route }
                      })
                    })
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }