/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import { publicPath, routerMode } from '@/config'
import Layout from '@/vab/layouts'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/callback',
    component: () => import('@/views/callback'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Inquiry',
    redirect: '/inquiryManagement',
    component: Layout,
    meta: {
      title: '询价管理',
      icon: 'database-2-line',
    },
    children: [
      {
        path: 'productQuery',
        name: 'ProductQuery',
        component: () => import('@/views/productQuery'),
        // component: Layout,
        meta: {
          title: '产品查询',
          // badge: '建设中',
          noKeepAlive: true,
        },
      },
      {
        path: 'inquiryManagement',
        name: 'InquiryManagement',
        component: () => import('@/views/inquiryManagement'),
        // component: Layout,
        meta: {
          title: '询价记录',
          noKeepAlive: true,
        },
      },
    ],
  },
  {
    path: '/truckloadProducts',
    name: 'BulkProducts',
    redirect: '/truckloadProducts',
    component: Layout,
    construction: 1,
    meta: {
      title: '产品中心',
      icon: 'suitcase-2-line',
    },
    children: [
      {
        path: 'truckloadProducts',
        name: 'TruckloadProducts',
        component: () => import('@/views/truckloadProducts'),
        // component: Layout,
        meta: {
          title: '零担产品',
          // badge: '建设中',
        },
      },

      {
        path: 'urbanDistributionProducts',
        name: 'UrbanDistributionProducts',
        component: () => import('@/views/urbanDistributionProducts'),
        // component: Layout,
        meta: {
          title: '城配产品',
          // badge: '建设中',
        },
      },

      {
        path: 'trailerProducts',
        name: 'TrailerProducts',
        component: () => import('@/views/trailerProducts'),
        // component: Layout,
        meta: {
          title: '拖车产品',
          // badge: '建设中',
        },
      },
      {
        path: 'wholeVehicleProducts',
        name: 'WholeVehicleProducts',
        component: () => import('@/views/WholeVehicleProducts'),
        // component: Layout,
        meta: {
          title: '短途拼专车',
          // badge: '建设中',
        },
      },
      // {
      //   path: 'productReport',
      //   name: 'ProductReport',
      //   component: () => import('@/views/productReport'),
      //   // component: Layout,
      //   meta: {
      //     title: '城配产品',
      //     // badge: '建设中',
      //   },
      // },
      // {
      //   path: 'productReport',
      //   name: 'ProductReport',
      //   component: () => import('@/views/productReport'),
      //   // component: Layout,
      //   meta: {
      //     title: '拖车产品',
      //     // badge: '建设中',
      //   },
      // },
    ],
  },
  // {
  //   path: '/trailerProducts',
  //   name: 'TrailerProducts',
  //   redirect: '/trailerProducts',
  //   component: Layout,
  //   construction:1,
  //   meta: {
  //     title: '拖车产品',
  //     icon: 'truck-line',
  //   },
  // },
  {
    path: '/dataReport',
    name: 'DataReport',
    redirect: '/dataStatistics',
    component: Layout,
    meta: {
      title: '数据报表',
      icon: 'bar-chart-2-line',
    },
    children: [
      {
        path: 'businessReport',
        name: 'BusinessReport',
        component: () => import('@/views/businessReport'),
        // component: Layout,
        meta: {
          title: '业务报表',
          // badge: '建设中',
        },
      },
      {
        path: 'productReport',
        name: 'ProductReport',
        component: () => import('@/views/productReport'),
        // component: Layout,
        meta: {
          title: '产品报表',
          // badge: '建设中',
        },
      },
    ],
  },
  {
    path: '/exportLog',
    name: 'Log',
    component: Layout,
    redirect: '/exportLog',
    meta: {
      title: '系统日志',
      icon: 'file-shield-2-line',
      // levelHidden: true,
    },
    children: [
      {
        path: 'exportLog',
        name: 'ExportLog',
        component: () => import('@/views/exportLog'),
        meta: {
          title: '导入失败列表',
          icon: 'align-bottom',
        },
      },
    ],
  },

  // {
  //   path: '/indexManagement',
  //   name: 'IndexManagement',
  //   redirect: '/homePage',
  //   component: Layout,
  //   meta: {
  //     title: '指标管理',
  //     icon: 'dashboard-line',
  //   },
  //   children: [
  //     {
  //       path: 'management',
  //       name: 'Management',
  //       component: () => import('@/views/homePage'),
  //       meta: {
  //         title: '指标管理',
  //       },
  //     },
  //   ],
  // },

  {
    path: '*',
    redirect: '/coreReport',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter()

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default router
