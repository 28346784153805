/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import Vue from 'vue'
import { asyncRoutes, constantRoutes, resetRouter } from '@/router'
import { getRouterList } from '@/api/router'
import { convertRouter, filterRoutes } from '@/utils/routes'
import { authentication, rolesControl } from '@/config'
import { isArray } from '@/utils/validate'

const state = () => ({
  routes: [],
})
const getters = {
  routes: (state) => state.routes,
}
const mutations = {
  /**
   * @description 多模式设置路由
   * @param {*} state
   * @param {*} routes
   */
  setRoutes(state, routes) {
    state.routes = routes
  },
  /**
   * @description 修改Meta
   * @param {*} state
   * @param options
   */
  changeMenuMeta(state, options) {
    function handleRoutes(routes) {
      return routes.map((route) => {
        if (route.name === options.name) Object.assign(route.meta, options.meta)
        if (route.children && route.children.length)
          route.children = handleRoutes(route.children)
        return route
      })
    }
    state.routes = handleRoutes(state.routes)
  },
}

//添加默认首页
const actions = {
  /**
   * @description 多模式设置路由
   * @param {*} { commit }
   * @param mode
   * @returns
   */
  async setRoutes({ commit }, mode = 'none') {
    // 默认前端路由
    let routes = [...asyncRoutes]
    // 设置游客路由关闭路由拦截(不需要可以删除)
    const control = mode === 'visit' ? false : rolesControl
    // 设置后端路由(不需要可以删除)
    if (authentication === 'all') {
      let allRoute=[
        // {
        // path: '/',
        // name: 'Chedule',
        // component: 'Layout',
        // redirect: '/index',
        // meta: {
        //   title: '欢迎',
        //   icon: 'home-2-line',
        //   levelHidden: true,
        // },
        // children: [
        //   {
        //     path: 'index',
        //     name: 'Index',
        //     component: '@/views/homePage',
        //     meta: {
        //       title: '欢迎',
        //       icon: 'home-2-line',
        //       noClosable: true,
        //     },
        //   },
        // ],
      // }
      ]
        const{ data } = await getRouterList()
      if (!isArray(data))
        Vue.prototype.$baseMessage(
          '路由格式返回有误！',
          'error',
          'vab-hey-message-error'
        )
      if (data[data.length - 1].path !== '*')
      for (let i = 0; i < data.length; i++) {
        allRoute.push(data[i])
      }
      allRoute[0].path='/'
      allRoute.push({ path: '*', redirect: '/404', meta: { hidden: true } })
      routes = convertRouter(allRoute)
    }
    // 根据权限和rolesControl过滤路由
    const accessRoutes = filterRoutes([...constantRoutes, ...routes], control)
    // 设置菜单所需路由
    commit('setRoutes', JSON.parse(JSON.stringify(accessRoutes)))
    // 根据可访问路由重置Vue Router
    await resetRouter(accessRoutes)
  },
  /**
   * @description 修改Route Meta
   * @param {*} { commit }
   * @param options
   */
  changeMenuMeta({ commit }, options = {}) {
    commit('changeMenuMeta', options)
  },
}

// const actions = {
//   /**
//    * @description 多模式设置路由
//    * @param {*} { commit }
//    * @param mode
//    * @returns
//    */
//   async setRoutes({ commit }, mode = 'none') {
//     // 默认前端路由
//     let routes = [...asyncRoutes]
//     // 设置游客路由关闭路由拦截(不需要可以删除)
//     const control = mode === 'visit' ? false : rolesControl
//     // 设置后端路由(不需要可以删除)
//     if (authentication === 'all') {
//       const {
//         data: { list },
//       } = await getRouterList()
//       if (!isArray(list))
//         Vue.prototype.$baseMessage(
//           '路由格式返回有误！',
//           'error',
//           'vab-hey-message-error'
//         )
//       if (list[list.length - 1].path !== '*')
//         list.push({ path: '*', redirect: '/404', meta: { hidden: true } })
//       routes = convertRouter(list)
//     }
//     // 根据权限和rolesControl过滤路由
//     const accessRoutes = filterRoutes([...constantRoutes, ...routes], control)
//     // 设置菜单所需路由
//     commit('setRoutes', JSON.parse(JSON.stringify(accessRoutes)))
//     // 根据可访问路由重置Vue Router
//     await resetRouter(accessRoutes)
//   },
//   /**
//    * @description 修改Route Meta
//    * @param {*} { commit }
//    * @param options
//    */
//   changeMenuMeta({ commit }, options = {}) {
//     commit('changeMenuMeta', options)
//   },
// }
export default { state, getters, mutations, actions }
