<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style lang="scss" scoped>
::v-deep {
  .vab-query-form[data-v-64063760] .left-panel {
    margin: 0px;
  }
  .el-table th > .cell{
    font-weight: 600 !important;
  }
}
</style>
